"use client";
import { View } from "react-native";
import useDevice from "src/hooks/useDevice";
import { paddingLarge, paddingMedium } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import DownloadBadge from "../components/DownloadBadge";
import AppInfoVideo from "./components/AppInfoVideo.web";

const AppInfo = () => {
	const { device } = useDevice();
	return (
		<>
			<View
				style={[
					{
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
					},
					device === "desktop" && {
						flexDirection: "row-reverse",
					},
				]}
			>
				<View
					style={[
						{
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							rowGap: paddingLarge,
						},
						device === "desktop" && {
							flex: device === "desktop" ? 1 : undefined,
							alignItems: "flex-start",
						},
					]}
				>
					<Text bold xl>
						Download swsh
					</Text>
					<View
						style={{
							flexDirection: "row",
							columnGap: paddingMedium,
							rowGap: paddingMedium,
							alignItems: "center",
							flexWrap: "wrap",
							justifyContent: "center",
						}}
					>
						<DownloadBadge variant="AppStore" />
						<DownloadBadge variant="GooglePlay" />
					</View>
				</View>
				<View
					style={[
						{
							alignItems: "flex-end",
						},
						device === "desktop" && {
							flex: 1,
							justifyContent: "center",
						},
					]}
				>
					<AppInfoVideo />
				</View>
			</View>
		</>
	);
};
export default AppInfo;
