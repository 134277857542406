"use client";

import { useMemo, useState } from "react";
import getOptimizedVideoUrl from "src/assets/__generated__/videos/marketing_for_you/getOptimizedVideoUrl";
import useDevice from "src/hooks/useDevice";
import useDim from "src/hooks/useDim";
import { MDiv } from "src/swsh-native";

const aspectRatio = 3 / 4;
const AppInfoVideo = () => {
	const { device } = useDevice();
	const { webmVideoUrl, mp4VideoUrl, movVideoUrl } = useMemo(() => {
		return {
			webmVideoUrl: getOptimizedVideoUrl({ size: device, format: "webm" }),
			mp4VideoUrl: getOptimizedVideoUrl({ size: device, format: "mp4" }),
			movVideoUrl: getOptimizedVideoUrl({ size: device, format: "mov" }),
		};
	}, [device]);
	const dim = useDim();
	const maxWidth = device === "desktop" ? dim.width / 2 : dim.width;
	const size = Math.max(
		0.9 * Math.min(maxWidth, dim.height * aspectRatio),
		Math.min(maxWidth, dim.height * aspectRatio, 400),
	);
	const [isActive, setIsActive] = useState(false);

	return (
		<MDiv
			initial={{ opacity: 0 }}
			animate={{
				opacity: isActive ? 1 : 0,
			}}
			transition={{
				duration: 0.15,
			}}
		>
			<video
				width={size}
				height={size}
				autoPlay
				muted
				loop
				playsInline
				onLoadedData={() => {
					setIsActive(true);
				}}
			>
				<source src={webmVideoUrl} type="video/webm" />
				<source src={movVideoUrl} type="video/quicktime" />
				<source src={mp4VideoUrl} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</MDiv>
	);
};
export default AppInfoVideo;
